<template>
  <body-layout>
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'statistics:settlement:dele'"
        >删除</a-button
      >
      <a-button type="primary" v-has="'statistics:settlement:addUser'" class="mr-3" @click="addUser"
        >添加结算人员</a-button
      >
      <a-button v-has="'statistics:settlement:reSettlement'" @click="exportExcel" class="mr-3">导出</a-button>
      <a-button
        type="primary"
        v-has="'statistics:settlement:reSettlement'"
        :loading="btnLoading"
        :disabled="!selectedRowKeys.length && userInfo.roles.some(item => item.roleCode == 'hr')"
        class="mr-3"
        @click="manualRate"
        >重新结算</a-button
      >
      <a-button type="danger" v-has="'statistics:settlement:reject'" class="mr-3" @click="reject">驳回</a-button>
      <!--       <a-button type="primary" v-has="'statistics:rating:post'" @click="sendEmail" v-if="allowSendEmailShow"
        >发送</a-button
      > -->
      <a-button
        v-has="'statistics:settlement:confirm'"
        type="primary"
        v-if="selectedRowKeys.length"
        @click="confirmRating"
        >确认</a-button
      >
    </div>
    <div class="table-page-search-wrapper" v-has="'statistics:settlement:search'" slot="search">
      <a-form layout="inline" @keyup.enter.native="searchQueryForm">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-select
              mode="multiple"
              :showArrow="false"
              :not-found-content="null"
              :filter-option="false"
              placeholder="请输入人员昵称"
              option-filter-prop="label"
              v-model="userIds"
              style="width: 200px"
              @change="searchQueryForm"
              @search="userSearch"
            >
              <a-select-option v-for="user in searchUsers" :label="user.userName" :key="user.userId">
                {{ user.userName }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-button type="primary" icon="search" @click="searchQueryForm">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :customRow="clickRow"
      :pagination="false"
      :scroll="{ x: 1000, y: 500 }"
      :loading="loading"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      @change="handleTableChange"
    >
      <template slot="status" slot-scope="text, record">
        {{ getStatusText(record) }}
      </template>
      <template slot="level" slot-scope="text, record">
        <a-input v-if="isEdit" v-model="record.level" />
        <span v-else>{{ record.level }}</span>
      </template>
    </a-table> -->
    <!--     <v-table
      ref="newTable"
      :selectedRowKeysprop="selectedRowKeys"
      :url="url"
      :queryParamProp="queryParam"
      @openModal="openModal"
      :tablePage="tablePage"
      :isEdit="isEdit"
      tableType="ratingTable"
      :columns="columns"
      @setRowkeys="setRowkeys"
      @setTablePage="setTablePage"
    /> -->
    <!--     <div style="display:flex;justify-content: end;z-index:99999">
      <a-pagination
        show-quick-jumper
        show-size-changer
        v-model="tablePage.currentPage"
        :page-size-options="['10', '20', '30', '40', '50']"
        :default-current="1"
        :defaultPageSize="tablePage.pageSize"
        :total="tablePage.totalResult"
        @showSizeChange="showSizeChange"
        @change="handlePageChange"
      />
    </div> -->
    <settlement-table
      :userInfo="userInfo"
      :isAdmin="isAdmin"
      :isEdit="isEdit"
      :queryParamProp="queryParam"
      @open="open"
      @setRowkeys="setRowkeys"
      ref="table"
    />
    <settlement-modal
      :userInfo="userInfo"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
      @modalGetPdf="modalGetPdf"
    ></settlement-modal>
    <!--     <div
      v-for="(item, index) in pdfData"
      :key="item.settleId"
      :id="'out-excel-' + index"
      style="padding:25px;position:absolute;top:1000px"
    >
      <div style="display: flex;justify-content:center;font-size:16px;font-weight:bold">
        <span>{{ item.settleMonth }}结算金额为：（{{ item.totalPrice }}{{ moneyTypeMap[item.priceUnit] }}）</span>
      </div>
      <a-table
        class="settlement-table"
        :pagination="false"
        bordered
        :columns="columnsOut"
        :data-source="item.extraSettles"
      >
      </a-table>
    </div> -->
    <a-modal :title="'添加结算人员'" dialogClass="translate-modal" v-model="addVisible" @cancel="addHandleCancel">
      <template slot="footer">
        <div style="display:flex;justify-content: space-between;">
          <span><a @click="addAgin">再次新建</a></span>
          <span>
            <a-button key="back" @click="addHandleCancel">
              取消
            </a-button>
            <a-button key="submit" type="primary" @click="addHandleOk">
              确定
            </a-button>
          </span>
        </div>
      </template>
      <a-form-model
        v-if="addVisible"
        ref="ruleForm"
        :model="formData"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="人员" prop="userId">
          <a-select
           class='settlement-user-select'
            :showArrow="false"
            :not-found-content="null"
            :filter-option="false"
            show-search
            placeholder="请选择人员"
            option-filter-prop="label"
            v-model="formData.userId"
            style="width: 100%"
            @search="userSearch"
          >
            <a-select-option v-for="user in searchUsers" :label="user.userName" :key="user.userId">
              {{ user.userName }}
            </a-select-option>
          </a-select>
          <!-- <a-input v-model="formData."></a-input> -->
        </a-form-model-item>
        <a-form-model-item label="部门" prop="departmentIds">
          <a-tree-select
            treeDefaultExpandAll
            v-model="formData.departmentIds"
            style="width: 100%"
            tree-checkable
            :tree-data="departList"
            search-placeholder="请选择部门"
            placeholder="请选择部门"
            @change="changeDepartList"
          />
        </a-form-model-item>
        <a-form-model-item label="结算月" prop="month">
          <a-month-picker
            format="YYYY-MM"
            :show-time="{ format: 'YYYY-MM' }"
            valueFormat="YYYY-MM"
            v-model="formData.month"
            placeholder="请选择结算月"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </body-layout>
</template>

<script>
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import vTable from '@/components/v-table/vTable'
import settlementModal from './modules/settlement-modal.vue'
import settlementTable from './modules/settlement-table.vue'
import { getAction, putAction, postAction, deleteAction, downloadFile } from '@/api/manage'
import { batchStyleInsertHeader, filterObj, handleArrayParams } from '@/utils/util'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import { axios } from '@/utils/request'
import { simpleDebounce } from '@/utils/util'
import { queryIdTree } from '@/api/api'
export default {
  mixins: [JeecgListMixin],
  components: {
    settlementModal,
    vTable,
    settlementTable
  },
  data() {
    return {
      formData: {
        userId: undefined,
        departmentIds: [],
        month: ''
      },
      departList: [],
      rules: {
        userId: [{ required: true, message: '请选择人员', trigger: 'change' }],
        month: [{ required: true, message: '请选择结算月', trigger: 'change' }],
        departmentIds: [{ required: true, message: '请选择部门', trigger: 'change' }]
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      addVisible: false,
      moneyTypeMap: {
        CNY: '元',
        TWD: '台币',
        JPY: '円',
        USD: '美元'
      },
      columns: [
        {
          title: '月份',
          dataIndex: 'month',
          width: 150,
          checked: true,
          isNum: false
        },
        {
          title: '部门',
          dataIndex: 'departName',
          checked: true,
          isNum: false
        },
        {
          title: '职位',
          dataIndex: 'positionName',
          checked: true,
          isNum: false
        },
        {
          title: '人员',
          dataIndex: 'nickName',
          checked: true,
          isNum: false
        },
        {
          title: '合作时长',
          dataIndex: 'duration',
          scopedSlots: { customRender: 'duration' },
          checked: true,
          isNum: true
        },
        {
          title: '任务数量',
          dataIndex: 'taskCount',
          scopedSlots: { customRender: 'taskCount' },
          checked: true,
          isNum: true
        },
        {
          title: '平均分',
          dataIndex: 'averageScore',
          scopedSlots: { customRender: 'averageScore' },
          checked: true,
          isNum: true
        },
        {
          title: '等级',
          scopedSlots: { customRender: 'level' },
          dataIndex: 'level',
          checked: true,
          isNum: false
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' },
          dataIndex: 'status',
          checked: true,
          isNum: false
        }
      ],
      columnsOut: [
        {
          title: '工作内容',
          dataIndex: 'taskName',
          width: 120,
          align: 'center',
          customHeaderCell: () => ({ style: { backgroundColor: '#f9e1d0' } })
        },
        {
          title: '平台',
          dataIndex: 'platformName',
          width: 120,
          align: 'center',
          customHeaderCell: () => ({ style: { backgroundColor: '#f9e1d0' } })
        },
        {
          title: '作品',
          dataIndex: 'productionName',
          width: 120,
          align: 'center',
          customHeaderCell: () => ({ style: { backgroundColor: '#f9e1d0' } })
        },
        {
          title: '章节范围',
          dataIndex: 'chapterRange',
          width: 120,
          align: 'center',
          customHeaderCell: () => ({ style: { backgroundColor: '#f9e1d0' } })
        },
        {
          title: '页数范围',
          dataIndex: 'pageRange',
          width: 120,
          align: 'center',
          customHeaderCell: () => ({ style: { backgroundColor: '#f9e1d0' } })
        },
        {
          title: '数量',
          dataIndex: 'settleTotal',
          width: 120,
          align: 'center',
          customHeaderCell: () => ({ style: { backgroundColor: '#f9e1d0' } })
        },
        {
          title: '单位',
          dataIndex: 'priceUnit',
          width: 120,
          align: 'center',
          customHeaderCell: () => ({ style: { backgroundColor: '#f9e1d0' } })
        },
        {
          title: '人员',
          dataIndex: 'username',
          width: 120,
          align: 'center',
          customHeaderCell: () => ({ style: { backgroundColor: '#f9e1d0' } })
        },
        {
          title: '单价',
          dataIndex: 'settlePrice',
          width: 120,
          align: 'center',
          customHeaderCell: () => ({ style: { backgroundColor: '#f9e1d0' } })
        },
        {
          title: '小计',
          dataIndex: 'totalPrice',
          width: 120,
          align: 'center',
          customHeaderCell: () => ({ style: { backgroundColor: '#f9e1d0' } })
        },
        {
          title: '备注',
          dataIndex: 'description',
          width: 120,
          align: 'center',
          customHeaderCell: () => ({ style: { backgroundColor: '#f9e1d0' } })
        }
      ],
      pdfData: [],
      positionList: [],
      btnLoading: false,
      tablePage: {
        currentPage: 1,
        pageSize: 50,
        totalResult: 0
      },
      translateUsers: [],
      userIds: [],
      monthFormat: 'YYYY-MM',
      month: '',
      isEdit: false,
      isAdmin: false,
      searchUsers: [],
      userInfo: {
        roles: []
      }
    }
  },
  methods: {
    changeDepartList() {
      console.log(this.formData.departmentIds)
    },
    getNowMonth() {
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      month = month > 9 ? month : '0' + month
      var nowMonth = year + '-' + month
      return nowMonth
    },
    getPreMonth() {
      var arr = this.getNowMonth().split('-')
      var year = arr[0] //获取当前日期的年份
      var month = arr[1] //获取当前日期的月份

      var year2 = year
      var month2 = parseInt(month) - 1
      if (month2 == 0) {
        //1月的上一月是前一年的12月
        year2 = parseInt(year2) - 1
        month2 = 12
      }

      if (month2 < 10) {
        //10月之前都需要补0
        month2 = '0' + month2
      }
      var preMonth = year2 + '-' + month2
      return preMonth
    },
    getDepartList() {
      queryIdTree().then(({ data }) => {
        this.departList = data
      })
    },
    addUser() {
      this.addVisible = true
      this.initForm()
      this.formData.month = this.getPreMonth()
    },
    initForm() {
      this.formData = {
        userId: undefined,
        departmentIds: [],
        month: ''
      }
    },
    addHandleCancel() {
      this.addVisible = false
      this.initForm()
    },
    addAgin() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const params = {
            ...this.formData,
            month: this.formData.month + '-01'
          }
          const res = await postAction('/add/settle', params)
          if (res.code == 200) {
            this.$message.success('保存成功')
            this.initForm()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    addHandleOk() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const params = {
            ...this.formData,
            month: this.formData.month + '-01'
          }
          const res = await postAction('/add/settle', params)
          if (res.code == 200) {
            this.$message.success('保存成功')
            this.initForm()
            this.addVisible = false
            this.$refs.table.getTableData()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    getPDF(item, index) {
      if (!this.pdfData.length) return
      this.$nextTick(() => {
        const dom = document.getElementById('out-excel-' + index)
        html2canvas(dom, {
          useCORS: true,
          width: dom.clientWidth,
          x: 0,
          y: 0,
          height: dom.clientHeight
        }).then(async canvas => {
          const image = canvas.toDataURL('image/jpeg')
          let PDF = new JsPDF('', 'pt', 'a4')
          PDF.addPage([dom.clientWidth, dom.clientHeight], 'landscape')
          PDF.addImage(image, 'JPEG', 0, 0, dom.clientWidth, dom.clientHeight)
          PDF.deletePage(1)
          let base64 = PDF.output('datauristring')
          const file = this.base64ToFile(base64, '工资条.pdf')
          let formData = new FormData()
          formData.append('pdfFile', file)
          formData.append('settleId', item.settleId)
          formData.append('totalPrice', item.totalPrice)
          postAction('/extra/settle/receive/pdf', formData)
        })
      })
    },
    base64ToFile(urlData, fileName) {
      let arr = urlData.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bytes = atob(arr[1]) // 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n)
      while (n--) {
        ia[n] = bytes.charCodeAt(n)
      }
      return new File([ia], fileName, { type: mime })
    },
    async exportExcel() {
      let ids = []
      if (this.selectionRows.length) {
        ids = this.selectionRows.map(item => item.id)
      }
      const res = await axios({
        url: '/excel/exportSettle',
        data: { ids },
        method: 'post',
        responseType: 'blob'
      })
      let url = window.URL.createObjectURL(new Blob([res]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '结算.xls')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) //下载完成移除元素
      window.URL.revokeObjectURL(url) //释放掉blob对象
      this.$message.success('导出成功')
    },
    batchDel: function() {
      let data = []
      let that = this
      if (!this.selectionRows.length) {
        this.$message.warning('请选择至少一条数据！')
        return
      }
      this.selectionRows?.forEach(item => {
        data.push(JSON.parse(JSON.stringify(item)).id)
      })
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        onOk: function() {
          that.loading = true
          deleteAction('/kt/settle/deleteBatch?' + new URLSearchParams(handleArrayParams({ ids: data })).toString())
            .then(res => {
              if (res.success) {
                that.$message.success(res.message || res.msg ? '删除成功' : '')
                that.$refs.table.getTableData()
                that.selectionRows = []
                that.selectedRowKeys = []
              } else {
                that.$message.warning(res.message || res.msg)
              }
            })
            .finally(() => {
              that.loading = false
            })
        }
      })
    },
    confirmRating() {
      postAction(
        '/kt/settle/confirm?' + new URLSearchParams(handleArrayParams({ ids: this.selectedRowKeys })).toString()
      ).then(res => {
        if (res.code == 200) {
          this.$message.success('确认完毕')
          this.$refs.table.getTableData()
          /*           if (res.data && res.data.length) {
            this.pdfData = res.data
            this.pdfData.forEach((item, index) => {
              this.getPDF(item, index)
            })
          } */
        }
      })
    },
    modalGetPdf(res) {
      this.pdfData = res.data
      this.pdfData.forEach((item, index) => {
        this.getPDF(item, index)
      })
    },
    reject: simpleDebounce(async function() {
      this.rejectSim()
    }, 500),
    rejectSim() {
      let taskIds = []
      this.selectionRows.forEach(item => {
        taskIds = [...taskIds, ...item.taskIds.split(',')]
      })
      taskIds = taskIds.join(',')
      postAction('/kt/settle/reject', { dataIds: this.selectedRowKeys, taskIds }).then(res => {
        if (res.code == 200) {
          this.$message.success('驳回成功')
          this.$refs.table.getTableData()
        }
      })
    },
    userSearch(value) {
      if (value) {
        this.searchUsers = this.translateUsers.filter(item => item.userName.toLowerCase().indexOf(value.toLowerCase()) > -1)
      } else {
        this.searchUsers = []
      }
    },
    open(row) {
      this.openDetail(row)
    },
    showSizeChange(e, num) {
      this.tablePage.pageSize = num
      this.$refs.newTable.loadData()
    },
    setTablePage(total) {
      if (total) {
        this.tablePage.totalResult = total - 0
      } else {
        this.tablePage.totalResult = 0
      }
    },
    handlePageChange() {
      this.$refs.newTable.loadData()
    },
    batchCencal() {
      this.isEdit = false
      this.$refs.table.getTableData()
    },
    openModal(record) {
      this.openDetail(record)
    },
    setRowkeys(records) {
      records = records.filter(item => !item.hasChild)
      this.selectedRowKeys = records.map(item => {
        return item.id
      })
      this.selectionRows = records
    },
    async batchEdit() {
      if (this.isEdit) {
        let data = []
        this.$refs.table.tableData?.forEach(item => {
          item.children?.forEach(i => {
            i.children?.forEach(row => {
              data.push(JSON.parse(JSON.stringify(row)))
            })
          })
        })
        if (data.length) {
          data.forEach(item => {
            item.month = item.month + '-01'
          })
          const res = await postAction('/rating/updateBatch', data)
          if (res.code != 500) {
            this.$message.success('保存成功')
            this.$refs.table.getTableData()
          } else {
            this.$message.error(res.msg)
          }
        }
      }
      this.isEdit = !this.isEdit
    },
    searchQueryForm() {
      if (this.month && this.month.format) {
        this.queryParam.month = this.month.format(this.monthFormat)
      } else {
        this.queryParam.month = ''
      }
      this.queryParam.userIds = this.userIds
      this.$refs.table.getTableData()
    },
    getAllPosition() {
      getAction('/sys/position/list').then(res => {
        if (res.success) {
          this.positionList = res.data.records
          this.positionList.forEach(one => {
            if (one.name == '翻译') {
              this.getAllTranslateUsers(one.id)
            }
          })
        }
      })
    },
    getAllTranslateUsers(postId) {
      getAction('/sys/sysDepart/userListToSelect', {
        positionId: postId
      }).then(res => {
        this.translateUsers = Object.assign([], res.data)
      })
    },

    getStatusText(record) {
      switch (record.status) {
        case 1:
          return 'HR确认' + (record.rejectType == 1 ? '(BOSS驳回)' : record.rejectType == 2 ? '(人员驳回)' : '')
        case 2:
          return 'BOSS确认' + (record.rejectType == 1 ? '(BOSS驳回)' : record.rejectType == 2 ? '(人员驳回)' : '')
        case 3:
          return '人员确认' + (record.rejectType == 1 ? '(BOSS驳回)' : record.rejectType == 2 ? '(人员驳回)' : '')
        case 4:
          return '人员已确认'
      }
    },

    manualRate: simpleDebounce(async function() {
      this.manualRateSim()
    }, 500),
    manualRateSim() {
      this.btnLoading = true
      let text = ''
      if (this.selectedRowKeys.length) {
        text = new URLSearchParams(handleArrayParams({ ids: this.selectedRowKeys })).toString()
      } else {
        text = 'ids='
      }
      getAction('/kt/settle/reSettle?' + text).then(res => {
        if (res.code == 200) {
          this.btnLoading = false
          this.$refs.table.getTableData()
          this.$message.success('结算成功')
        } else {
          this.btnLoading = false
          this.$message.error(res.msg || res.message)
        }
      })
    },
    sendEmail: simpleDebounce(async function() {
      this.sendEmailSim()
    }, 500),
    sendEmailSim() {
      this.$refs.table.reExpend()
      postAction('/rating/send_email').then(({ success, msg }) => {
        if (success) {
          this.$message.success('发送成功')
          this.$refs.table.getTableData()
        } else {
          this.$message.error(msg)
        }
      })
    }
  },
  created() {
    this.getAllPosition()
    this.getDepartList()
    this.userInfo = Vue.ls.get(USER_INFO)
    this.isAdmin = false
    if (this.userInfo.roles) {
      this.userInfo.roles.forEach(one => {
        if (one.roleCode == 'admin' || one.roleCode == 'boss' || one.roleCode == 'hr') {
          this.isAdmin = true
        }
      })
    }
  },

  computed: {
    allowSendEmailShow() {
      let allow = true
      if (this.dataSource) {
        this.dataSource.forEach(one => {
          if (one.firstStagePass) {
            allow = false
          }
        })
      }
      return allow
    }
  }
}
</script>

<style lang="less" scoped>
.settlement-table {
  /deep/.ant-table-thead > tr > th {
    border-top: 2px solid black !important;
    border-left: 2px solid black !important;
    border-bottom: 2px solid black !important;
  }
  /deep/.ant-table-thead > tr > th:last-of-type {
    border-right: 2px solid black !important;
  }
  /deep/.ant-table-tbody > tr > td {
    border-bottom: 2px solid black !important;
    border-left: 2px solid black !important;
  }
  /deep/.ant-table-tbody > tr > td:last-of-type {
    border-right: 2px solid black !important;
  }
  /deep/ .ant-table-tbody > tr:hover > td {
    background: transparent !important;
  }
}
.red {
  color: red;
}
/deep/ .ant-calendar-picker-input::-webkit-input-placeholder {
  font-size: 14px !important;
}
/deep/ .ant-calendar-picker-input::-moz-input-placeholder {
  font-size: 14px !important;
}

/deep/ .ant-calendar-picker-input::-ms-input-placeholder {
  font-size: 14px !important ;
}
</style>
